.xcn-stats-card {
  width: 18.5rem;
  height: 6rem;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
}

.xcn-stats-card-dough {
  width: 26rem;
  height: 10rem;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
}

.xcn-stats-card-footer {
  margin-top: auto;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  background-color: #f1f1f1;
  padding: 0.5rem;
  border-radius: 0 0 12px 12px;
}

.xcn-stats-icon {
  height: 40px;
  min-width: 50%;
}

.xcn-stats-card-value {
  font-size: 25px;
  font-weight: 600;
}

.xcn-card-change {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0.25rem;
}

.statsForCenterAuth {
  width: 17rem;
  height: 12rem;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  margin-top: 50px;
}

.csrReportDivider {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  height: 2rem;
  background-color: aqua;
}
.button {
  width: 100%;
}

.xcn_plus_icon{
  color: #dcdcdc
}


.xcn_plus_icon:hover{
  color: #acaaaa;
  cursor: pointer;
}
